import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

export enum ArticlePageType {
  DEFAULT = 'DEFAULT',
  LEISURE = 'LEISURE'
}

interface DefaultFilterArgs {}

interface GetArticlesFilterArgs extends DefaultFilterArgs {
  excludeFirst?: boolean;
}

interface GetArticleFilterArgs extends DefaultFilterArgs {}

interface GetPaginatedArticlesFilterArgs extends DefaultFilterArgs {
  page: number;
}

export interface ISeo {
  title: string;
  description: string;
}

export interface IMainPage {
  type: ArticlePageType;
  image?: string;
  imageTitle?: string;
  imageOpacity?: number;
  title?: string;
  titleSEO: string;
  description: string;
  descriptionSEO?: string;
  showSteps: boolean;
  pathname: string;
}

export interface IArticle {
  id: number;
  type: ArticlePageType;
  image: string;
  imageOverlay: string;
  video?: string;
  title?: string;
  ingress?: string;
  quote?: string;
  content?: string;
  slug: string;
  link: string;
  card: {
    title: string;
    description: string;
    buttonLabel?: string;
  };
  seo: ISeo;
}

interface IArticleContext {
  main: () => IMainPage | null;
  articles: (args: GetArticlesFilterArgs) => IArticle[];
  getArticle: (args: GetArticleFilterArgs) => IArticle | null;
  getFirstArticle: () => IArticle | null;
  getPaginatedArticles: (args: GetPaginatedArticlesFilterArgs) => IArticle[];
  getArticlePageType: () => string;
  getArticleCountByType: () => number;
}

export const DeliveryContext = React.createContext<IArticleContext>({
  main: () => null,
  articles: () => [],
  getArticle: () => null,
  getFirstArticle: () => null,
  getPaginatedArticles: () => [],
  getArticlePageType: () => '',
  getArticleCountByType: () => 0
});

export const DeliveryContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const params = useParams<{ type?: string; slug?: string }>();
  const location = useLocation();

  history.listen(() => {
    window.scroll(0, 0);
  });

  const main: IMainPage[] = [
    {
      type: ArticlePageType.DEFAULT,
      image: 'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/top_bg.jpg',
      imageTitle: '/PrivatMegleren-leveranse-salg-bolig-eiendom',
      titleSEO: 'Leveransebeskrivelse  - PrivatMegleren',
      title: 'Alt du lurer på – fra start til solgt',
      description:
        '<p>For de aller fleste av oss er eiendom det mest verdifulle vi eier. Det er derfor mye som står på spill de få gangene i livet vi skal gjennom et salg. Kanskje er du litt i tvil om hva som er ditt ansvar, og hva du kan forvente i hvert steg av prosessen?</p><p>I denne serien svarer PrivatMegleren og eiendomsmegler Thomas Klovland på alt du lurer på, fra start til solgt. </p>',
      descriptionSEO:
        'PrivatMegleren hjelper deg tett gjennom hele salgsprosessen for et best mulig resultat av din bolig, leilighet, hytte eller annen eiendom',
      showSteps: true,
      pathname: '/leveransebeskrivelse-selge-bolig'
    },
    {
      type: ArticlePageType.LEISURE,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/top_leisure_2.jpg',
      imageOpacity: 0.3,
      titleSEO: 'Fritid - PrivatMegleren',
      title: 'Ambassadører for området',
      description:
        '<p>Det er gjerne større følelser knyttet til kjøp av fritidseiendom enn ved kjøp av eiendom. Kjøperne spør gjerne mer om nærområdet enn om selve hytta. Da er det avgjørende at megler har god lokalkunnskap.</p><p>Det er flere forskjeller ved prosessen for kjøp og salg av fritidseiendom sammenlignet med  et eiendomssalg. I denne artikkelserien gir vi deg tips om hva du skal se etter ved valg av megler av fritidseiendom</p>',
      showSteps: false,
      pathname: '/leveransebeskrivelse-selge-bolig/fritid'
    }
  ];

  const articles: IArticle[] = [
    {
      id: 0,
      type: ArticlePageType.DEFAULT,
      seo: {
        title: 'Smart start gir best resultat',
        description:
          'Eiendomsmegler Christer Vikebø i PrivatMegleren har gitt råd og oppfølging til eiendomsselgere i over tyve år. Han mener utvilsomt at en god start er nødvendig om du skal få et godt resultat.'
      },
      card: {
        title: 'Komme i gang',
        description:
          'I denne serien svarer PrivatMegleren og eiendomsmegler Thomas Klovland på alt du lurer på, fra start til solgt.',
        buttonLabel: 'Til episode'
      },
      title: 'Smart start gir best resultat',
      video: 'https://player.vimeo.com/video/672745753?amp;loop=0&background=0',
      ingress: `
      <p><b>For de aller fleste henger kjøp og salg av eiendom tett sammen – ny eiendom medfører som oftest at man overlater den gamle til nye eiere. De færreste kjøper og selger imidlertid eiendom eller hytte jevnlig, og trenger derfor råd for om hvordan man kommer i gang.</b></p>
      <p>Eiendomsmegler Christer Vikebø i PrivatMegleren har gitt råd og oppfølging til eiendomsselgere i over tyve år. Han mener utvilsomt at en god start er nødvendig om du skal få et godt resultat.</p>
      <p>– Noen har vært i samme situasjon en gang eller to tidligere – andre har kanskje aldri solgt en eiendom før. Uavhengig av hvor godt man kjenner salgsprosessen fra tidligere, er det lønnsomt å gjøre noen smarte grep allerede fra starten av. Slik leder resten av prosessen til et resultat du blir fornøyd med, sier Christer Vikebø i PrivatMegleren.</p>
      <p>PrivatMegleren sin tydelige anbefaling er at du bør forvente litt mer av megleren din.</p>`,
      quote:
        'Du kjøper tross alt ikke et hyllevareprodukt, men en personlig rådgiver',
      content: `
      <h4>Våg å være litt kresen</h4><img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode1.jpg" alt="artikkel-bilde" />
      <p>Når du skal sette bort jobben med å forvalte din eiendomsformue til en megler mener Forbrukerrådet at du bør hente tilbud fra flere eiendomsmeglere, gjerne tre til fire. Det er også PrivatMeglerens anbefaling. På denne måten får du et godt sammenligningsgrunnlag, uten at du mister oversikten over prisene og vilkårene de ulike tilbyr.</p>
      <p>– Du kjøper tross alt ikke et hyllevareprodukt, men en personlig rådgiver som skal gi deg en god og trygg prosess og et godt resultat. Derfor bør du vektlegge meglerens kunnskap og erfaring – kombinert med den personlige kjemien dere i mellom. Du kan med god grunn være litt kresen, forklarer Vikebø.</p>
      <p>I dialogen bør du undersøke hvilken type eiendommer megleren har solgt tidligere, og hvordan megleren tenker å ivareta dine interesser, og ikke minst hva megleren kan gjøre for å fremme eiendommen din på den beste mulige måten.</p>
      <h4>Gjør et kvalifisert valg</h4>
      <p>Du undersøker ulike meglere og hvem som har solgt lignende eiendommer i nærheten, og hvordan de ulike presenterer eiendommer med bilder, video, tekster og annonser. </p>
      <p>Du rådfører deg med venner og bekjente, leser anmeldelser og tilbakemeldinger fra tidligere kunder. Søket etter den riktige megleren snevres stadig inn, og du står igjen med et lite knippe alternativer. Hva gjør man så?</p>
      <p>Når du har funnet noen meglere du ønsker å vurdere er det lurt å invitere dem på befaring. På befaringen kjenner du raskt hvem som gir et godt inntrykk gjennom å være godt forberedt. Hvilken statistikk har de fra tidligere salg i området, hvordan var interessen, og hvilket kontaktnett tilbyr de?</p>
      <p>Typiske spørsmål du bør få svar på fra meglerne er hvem de vet ønsker å flytte til området, hvordan de planlegger å selge inn området til potensielle interessenter, og hvordan de vil jobbe for å sikre best mulig salg, og ikke minst hva de vil gjøre dersom de i første runde ikke klarer å innfri forventningene.<p>
      <p>Som eiendomsselger er det som hovedregel ikke bindende og oftest uten kostnad å innlede dialog med eiendomsmeglere. De aller fleste tilbyr også befaring og utarbeider tilbud for oppdraget uten kostnad. Slik får du muligheten til å vurdere hver megler i ro og mak, og kan føle deg trygg på at du tar et riktige valg.
      </p>
      `,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode1.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/episodenummer/1.png',
      slug: 'komigang',
      link: '/leveransebeskrivelse-selge-bolig/komigang'
    },
    {
      id: 1,
      type: ArticlePageType.DEFAULT,
      seo: {
        title: 'Slik får du best utbytte av befaringen',
        description:
          'En befaring er ikke bare til for at megleren skal bli kjent med eiendommen din. Det er også en gyllen anledning for deg å erfare hvordan megleren presterer – allerede før oppdraget har startet.'
      },
      card: {
        title: 'Befaring',
        description:
          'En befaring er ikke bare til for at megleren skal bli kjent med eiendommen din. Det er også en gyllen anledning for deg å erfare hvordan megleren presterer – allerede før oppdraget har startet.',
        buttonLabel: 'Til episode'
      },
      title: 'Slik får du best utbytte av befaringen',
      video: 'https://player.vimeo.com/video/672745794?amp;loop=0&background=0',
      ingress: `
      <p><b>En befaring er ikke bare til for at megleren skal bli kjent med eiendommen din. Det er også en gyllen anledning for deg å erfare hvordan megleren presterer – allerede før oppdraget har startet. Det er smart Smarte selgere av eiendom bruker også muligheten til å sanke profesjonelle råd om hvilke eventuelle oppgraderinger som kan lønne seg.</b></p>
      <p>Før en befaring er det ikke mye du som selger trenger å gjøre, men det er en fordel om du er litt forberedt. Start gjerne med å gjøre deg noen tanker om tidshorisont for salget og samle sammen informasjon om ting du vet påvirker verdien.</p>
      <p>– Vær smart og bruk befaringen til å få en eiendomsmeglers råd om hva som lønner seg å gjøre av oppussing og utbedringer før et salg. Start gjerne med å samle informasjon du vet påvirker verdien av eiendommen, og gjør deg gjerne noen tanker om tidshorisont for salget. Slik kan du spare deg både tid og penger, forteller eiendomsmegler Christian Stenerud i PrivatMegleren Panorama.</p>
      <p>PrivatMegler Christian Stenerud har et trent øye for hvilke grep som er lønnsomme før du skal selge en eiendom eller hytte. Han mener derfor befaringen er et steg av salget hverken megler eller selger skal ta lett på.</p>
      <p>God kartlegging og planlegging tidlig i prosessen kan ha stor effekt senere i salget. Dette er noe de beste meglerne i bransjen er godt trent på. Du kan derfor få råd om hvilke oppgraderinger du eventuelt bør gjøre. Bør du pusse opp badet, eller er det mer lønnsomt å investere i en energieffektiv peisovn i stuen? En dreven megler kjenner markedet og hva som skal til for å utløse et salg i området eiendommen er i.</p>`,
      quote:
        'En dreven megler vet hva som skal til for å utløse et salg i ditt område',
      content: `
      <h4>Imponer kjøperen</h4><img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode2.jpg" alt="artikkel-bilde" />
      <p>Under befaringen jakter PrivatMegleren alltid etter eiendommens gylne kvadrat – en «wow-faktor» som imponerer kjøperen og gjør at eiendommen skiller seg positivt ut i markedet. Nesten uten unntak finnes det unike egenskaper ved alle eiendommer som aktivt fremheves i presentasjonen og markedsføringen.</p>
      <p>Det kan være noe åpenbart som beliggenheten, ekstra god takhøyde eller en unik planløsning. Andre ganger krever det litt mer teft å se hva som er særegent. En opprydning i trærne i hagen kan avdekke en formidabel utsikt. Andre ganger kan du endre planløsningen slik at en eiendom får en utleiedel, et ekstra soverom – og vips kan kjøper regne med ekstra leieinntekter, eller et barn til.</p>
      <h4>– Ikke la deg lokke</h4>
      <p>Under befaringen bør du be meglerne du har invitert om å vise planen de har for salgsprosessen. Hvordan ser de for seg at eiendommen skal markedsføres, og når. Hvordan skal <a href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig/visning">visning</a> være, hva er planen for  <a href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig/budrunde">budrunden</a>, og hva vil de gjøre om eiendommen ikke blir solgt? Stenerud anbefaler også at du merker deg hvordan ulike meglerne begrunner prisantydningen de presenterer. Riktig prisantydning krever kunnskap og et godt stykke arbeid.</p>
      <p>– Lytt mer til hvordan megleren argumenter for prisantydningen enn selve summen alene. Husk at det til slutt er markedet og ikke prisantydningen som bestemmer hva du får for eiendommen din. Derfor bør du ikke la deg lokke til å velge megler basert en urealistisk høy prisvurdering, forklarer Stenerud.</p>
      <p>Prisantydningen skal ikke være lavere enn det du er villig til å selge for, og du kan derfor ikke takke nei om du får bud på prisantydning. Dette vil være lovstridig.</p>
      <p>En god megler vil alltid basere prisantydningen på et solid forarbeid. Hos PrivatMegleren har meglerne tilgang til all tidligere salgshistorikk for nærområdet i databasen Eiendomsverdi. I tillegg vektlegger meglerne en rekke andre faktorer, som teknisk tilstand, beliggenhet, markedet på tidspunktet og hvilke andre eiendommer som er til salgs i området. Forventet salgssum baseres så på en analyse av denne historikken, fakta om eiendommen, og ikke minst meglerens kunnskap og egen erfaring.</p>
      <h4>Selg ærlig</h4>
      <p>Fra januar 2022 ble reglene for salg av eiendom skjerpet i kjøpers favør. Du kan ikke lenger selge eiendommen «som den er», og terskelen for når noe kan anses som en mangel er lavere.</p>
      <p>Om det mangler dokumentasjon på arbeid som normalt skal utføres av faglærte vil det føre til dårligere tilstandsgrad i tilstandsrapporten. Tilstandsrapporten gir en teknisk vurdering av eiendommen og er utført av en takstmann. Rapporten beskriver avvik fra forskriftene og behov for vedlikehold. Stenerud råder deg derfor til å skaffe dokumentasjon på arbeider som er utført i eiendommen eller hytten din.</p>
      <p>– Prøv for all del ikke å skjule noe – det lønner seg aldri. Finn frem eller skaff til veie dokumentasjon på alt som er gjort i huset eller hytten. Husk at du også kan dokumentere egeninnsats og legge ved bilder av det som er gjort, råder han.</p>
      `,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode2.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/episodenummer/2.png',
      slug: 'befaring',
      link: '/leveransebeskrivelse-selge-bolig/befaring'
    },
    {
      id: 3,
      type: ArticlePageType.DEFAULT,
      seo: {
        title: 'La kjøperen flytte inn før du selger',
        description:
          'En sentral del av meglerens håndverk er å forme det visuelle inntrykket av eiendommen du skal selge. Eiendomsmegler Lillian Charlotte Nerlinger i PrivatMegleren Allé går metodisk til verks for at potensielle kjøpere skal sitte igjen med en god følelse etter å ha sett eiendommen.'
      },
      card: {
        title: 'Hvordan presentere eiendommen',
        description:
          'En sentral del av meglerens håndverk er å forme det visuelle inntrykket av eiendommen du skal selge. Eiendomsmegler Lillian Charlotte Nerlinger i PrivatMegleren Allé går metodisk til verks for at potensielle kjøpere skal sitte igjen med en god følelse etter å ha sett eiendommen.',
        buttonLabel: 'Til episode'
      },
      title: 'La kjøperen flytte inn før du selger',
      video: 'https://player.vimeo.com/video/672745834?amp;loop=0&background=0',
      ingress: `
      <p><b>En sentral del av meglerens håndverk er å forme det visuelle inntrykket av eiendommen du skal selge. Eiendomsmegler Lillian Charlotte Nerlinger i PrivatMegleren Allé, går metodisk til verks for at potensielle kjøpere skal sitte igjen med en god følelse etter å ha sett eiendommen.</b></p>
      <p>Selv om det til tider kan oppleves som at enkelte eiendommer selger seg selv, vet likevel de mer erfarne meglerne at riktig styling og gode, tiltalende bilder og gjennomarbeidet planlegging kan utgjøre en betydelig forskjell, både for den endelige kjøpesummen og tiden det tar å selge.</p>
      <p>– Jevnt over er god tilrettelegging og styling en av de beste investeringene du kan gjøre når du skal selge en eiendom. Dagens kjøpere er følelsesstyrte, og vil gjerne identifisere seg med en eiendom eller hytte de skal kjøpe; de er bevisst eller ubevisst opptatt av helheten og detaljer i interiøret, forteller Lillian Charlotte Nerlinger i PrivatMegleren Allé.</p>
      <p>Erfaringsmessig er det er mange som ikke leser salgsoppgaven er det en stor andel kjøpere som ikke har satt seg godt inn i plantegningen og mulighetene som eiendommen byr på. Det er derfor en fordel å visualisere alternativer for planløsning og innredning.</p>
      <p>Med riktig styling kan man gi potensielle kjøpere en følelse av romstørrelse, og la de se muligheter for innredning. Slik vil flere fatte interesse for å kjøpe.</p>`,
      quote:
        'Vi jakter på høydepunktene til eiendommen og hva vi kan gjøre for å løfte inntrykket',
      content: `
      <h4>Jakter på høydepunktene</h4><img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode3.jpg" alt="artikkel-bilde" />
      <p>Som regel ser de fleste selgere verdien av å rydde, tømme og renske opp i interiøret for å få frem kvalitetene ved eiendommen. I denne forbindelse kan det være smart å sørge for mellomlagring av møbler til overs. Hvor mye styling som er nødvendig før et salg avhenger imidlertid av eiendommen i seg selv og markedet.</p>
      <p>– Det holder ikke nødvendigvis å kjenne til trender og ha et øye for interiørvalg, man må også kjenne markedet og kjøpergruppen. Skal man tilføre noe må det ikke bare passe til eiendommen, men også de som er i målgruppen for å kjøpe. Det handler om å la de nye eierne flytte litt inn, før du selv har flyttet ut, forklarer megleren.</p>
      <p>Å vite hva som skal til for å heve førsteinntrykket av eiendommen bygger ikke bare på erfaring og kunnskap om markedet. For at ingenting skal overlates til tilfeldighetene har PrivatMegleren derfor håndplukket egne stylister og fotografer til å bistå med de riktige grepene.</p>
      <p>– Vi jakter på høydepunktene og hva vi kan gjøre for å løfte inntrykket dit at kjøperen kjenner at «her vil jeg trives!», sier Nerlinger.</p>
      <h4>Et hjem med personlighet</h4>
      <p>På selve visningsdagen kan du gjøre små grep som å lufte godt, slik at inneluften er frisk og ny. Sett gjerne på gulvvarme i alle rom som har dette, slik at man får en lun, frisk og god følelse idet man kommer inn. De som kommer på visning, bør også kjenne igjen eiendommen de har sett på bildene. Det er viktig for å opprettholde tilliten.</p>
       <p>PrivatMegleren bruker også romplakater aktivt på visningsdagen, som gir utvidet informasjon om rommene man står i. Romplakatene fremhever det som ikke nødvendigvis er lett å se for de blotte øyet, og som ikke alle har fått med seg fra prospektet.</p>
       <p>På romplakatene kan potensielle kjøpere få nyttige opplysninger, slik som gulvtypen i rommet, takhøyde eller hvordan solforholdene er. Man kan også vise bilder av parkeringsplass i felles garasje, boder i fellesanlegg eller sommerbilder av hagen om visningen er vinterstid. Dersom du for eksempel har valgt å ikke pusse opp badet kan du til og med hente inn et bindende pristilbud og bruke romplakaten til å fortelle om dette.</p> 
       <p>Det er også rom for å lage noen plakater som er litt annerledes. Et eksempel er å lage en «ukesmeny» for hvordan du som selger har brukt nærmiljøet: Mandag er det fint å hente ferskt brød hos bakeren på hjørnet; tirsdag et besøk hos den lokale fiskehandleren – og så videre. Det gjelder å tenke utradisjonelt.</p>
       <p>– Vi vet at romplakatene blir studert nøye og er godt likt av de som kommer på visning. Det forsterker det visuelle, og løfter frem det folk kanskje ikke har tenkt på, slik at flere blir interessert i å kjøpe, avslutter Nerlinger.</p>`,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode3.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/episodenummer/3.png',
      slug: 'hvordan-presentere-boligen',
      link: '/leveransebeskrivelse-selge-bolig/hvordan-presentere-boligen'
    },
    {
      id: 4,
      type: ArticlePageType.DEFAULT,
      seo: {
        title: 'Finner de som forelsker seg i eiendommen din',
        description:
          'Det er ikke alltid enkelt å vite på forhånd hvem som er interessert i å kjøpe eiendommen din. Kanskje den rette kjøperen er noen som ikke egentlig hadde tenkt å kjøpe eiendom nå? For å øke effekten av markedsføringen, lønner det seg derfor å ta i bruk annonseringsverktøy som sørger for at annonseringen treffer riktig.'
      },
      card: {
        title: 'Markedsføring',
        description:
          'Det er ikke alltid enkelt å vite på forhånd hvem som er interessert i å kjøpe eiendommen din. Kanskje den rette kjøperen er noen som ikke egentlig hadde tenkt å kjøpe eiendom nå? For å øke effekten av markedsføringen, lønner det seg derfor å ta i bruk annonseringsverktøy som sørger for at annonseringen treffer riktig.',
        buttonLabel: 'Til episode'
      },
      title: 'Finner de som forelsker seg i eiendommen din',
      video: 'https://player.vimeo.com/video/834368541?amp;loop=0&background=0',
      ingress: `
      <p><b>Det er ikke alltid enkelt å vite på forhånd hvem som er interessert i å kjøpe eiendommen din. Kanskje den rette kjøperen er noen som ikke egentlig hadde tenkt å kjøpe eiendom nå? For å øke effekten av markedsføringen, lønner det seg derfor å ta i bruk annonseringsverktøy som sørger for at annonseringen treffer riktig.</b></p>
      <p>– Når du først har bestemt deg for å selge eiendommen din, er det naturlig at du ønsker størst mulig interesse for den. Det øker sannsynligheten for at den blir solgt til en pris du blir fornøyd med, forklarer Christer Vikebø i PrivatMegleren.</p>
      <p>Som selgeres nærmeste rådgiver ved salg av alle slags type eiendommer, vet han hva som må til for å få god effekt av målrettet markedsføring. </p>
      <p>Eiendomskjøp handler om følelser – noen skal forelske seg i din eiendom</p>
      `,
      quote:
        'Eiendomskjøp handler om følelser – noen skal forelske seg i din eiendom',
      content: `
      <h4>Selger bolig med Presisjon</h4><img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode4.jpg" alt="artikkel-bilde" />
      <p>Når <a href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig/befaring">befaring</a> er gjennomført, og eiendommen er stylet og <a href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig/hvordan-presentere-boligen">klargjort</a> for å vises frem, er det klart for annonsering. Tradisjonelt har avisannonsering vært hyppig brukt. Senere har også digitale tjenester som FINN.no og meglerens nettsider blitt viktige kanaler. Mange spør seg kanskje om man virkelig har behov for mer enn dette.</p>
      <p>– Vår erfaring tilsier at det er flere som ikke er på jakt etter ny eiendom, enn de som søker aktivt. De som gjør aktive søk vil uansett se eiendommen din når annonseringen starter.</p>
      <p>PrivatMegleren har enerett på annonseverktøyet Presisjon, som finner de som ikke allerede har satt opp søk etter en eiendom som din, forteller Christer Vikebø.</p>
      <p>Den store fordelen med teknologien til Presisjon, er at den klarer å finne potensielle kjøpere basert på en rekke ulike faktorer – ut over de vanlige søkekriteriene. Løsningen lager mange versjoner av salgsannonsen, med forskjellige kombinasjoner av bilder og tekst. Deretter måler teknologien hvilken annonsekombinasjon som engasjerer mest hos de ulike mottakerne. Basert på engasjementet tilpasses annonseringen for å bli mer målrettet og treffsikker.</p>
      <h4>Forelsker seg i tanken på ny eiendom</h4>
      <p>Med Presisjon presenteres eiendommen din derfor på ulikt vis, mot potensielle kjøpere med ulike behov. Om noen for eksempel viser seg å være opptatt av matlaging, vil vedkommende se forskjellige versjoner av annonsene der kjøkkenet blir fremhevet spesielt – nettopp fordi det er en viktig faktor for akkurat denne personen. Andre igjen ser etter stor hage eller lyse barnerom. </p>
      <p>Folk har forskjellige ønsker og behov, og derfor må også annonsene være ulike.</p>
      <p>Slik kan vi finne de kjøperne som kanskje ikke visste at de kunne tenke seg et nytt sted å bo, men som likevel er interessert i å kjøpe når de ser den riktige eiendommen presentert.</p>
      <p>– Eiendomskjøp handler om følelser. Vi hjelper deg som selger med å finne de som forelsker seg i akkurat din eiendom, og umiddelbart ser for seg sitt liv i den, avslutter Vikebø.</p>
      `,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode4.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/episodenummer/4.png',
      slug: 'markedsforing',
      link: '/leveransebeskrivelse-selge-bolig/markedsforing'
    },
    {
      id: 5,
      type: ArticlePageType.DEFAULT,
      seo: {
        title: 'Visning etter skreddersøm',
        description:
          'Det fremste målet med visningen er å gi kjøperen et godt inntrykk av eiendommen som selges. Visningen er et svært viktig ledd i salgsprosessen – det er her eiendommen og interessentene møtes for første gang, og er den beste muligheten å bli kjent med eiendommen, se på helheten og få svar på relevante spørsmål.'
      },
      card: {
        title: 'Visning',
        description:
          'Det fremste målet med visningen er å gi kjøperen et godt inntrykk av eiendommen som selges. Visningen er et svært viktig ledd i salgsprosessen – det er her eiendommen og interessentene møtes for første gang, og er den beste muligheten å bli kjent med eiendommen, se på helheten og få svar på relevante spørsmål.',
        buttonLabel: 'Til episode'
      },
      title: 'Visning etter skreddersøm',
      video: 'https://player.vimeo.com/video/768437009?amp;loop=0&background=0',

      ingress: `
      <p><b>Det fremste målet med visningen er å gi kjøperen et godt inntrykk av eiendommen som selges. Visningen er et svært viktig ledd i salgsprosessen – det er her eiendommen og interessentene møtes for første gang, og er den beste muligheten å bli kjent med eiendommen, se på helheten og få svar på relevante spørsmål.</b></p>
      <p>Hvordan eiendommen presenteres på visningen må stemme overens med hvordan den er presentert i markedsføringen. Det gir et godt inntrykk av eiendommen og megleren, og bidrar til å skape tillit og trygghet for de potensielle kjøperne.</p>
      <p>– En av de viktigste oppgavene til megleren under visningen er å være tilgjengelig for å presentere eiendommen, og svare på spørsmål fra de fremmøtte. Det er ofte stor variasjon i forkunnskapen til interessentene, og i hvilken grad de er reelt interessert i å legge inn bud etter visningen, forklarer Lillian Charlotte Nerlinger i PrivatMegleren Allé.</p>`,
      quote:
        'Vi bruker energien vår målrettet mot de vi mener faktisk vil gi bud',
      content: `
      <h4>Faktiske kjøpere eller nysgjerrige naboer?</h4><img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode5.jpg" alt="artikkel-bilde" />
      <p>Noen leter aktivt etter et nytt sted å bo og kommer veldig godt forberedt, andre vet ikke helt hva de ønsker og bruker visningen for å bli inspirert. Og andre igjen er bare nysgjerrige naboer uten flytteplaner.</p>
      <p>Det er derfor viktig at megleren bruker tid på å bli kjent med interessentene for å kartlegge behovene deres og finne ut av om de er reelt interessert eller bare innom for å kikke. Megler kartlegger behov og bruker dette senere i arbeidet med å opprettholde interesse. Noen ønsker å gå i fred og titte selv, mens andre behøver meglers hjelp til å se muligheter og fordeler ved eiendommen.</p>
      <p>Når man er på visning i en eiendom eller hytte, kan det være krevende å se for seg hvordan enkle endringer kan gjøre den enda bedre tilpasset egne behov og ønsker. Det kan for eksempel være å sette opp en lettvegg for å lage et ekstra rom, fjerne trær og beskjære busker i hagen for å gi mer lys inn, eller bytte ut et gammelt ildsted til en moderne peisovn. Det er meglerens oppgave å hjelpe til med å forklare mulighetene som finnes for den neste eieren.</p>
      <p>– I tillegg bruker vi i PrivatMegleren romplakater på visninger. Dette er fysiske plakater med informasjon som plasseres ut for å fremheve vesentlige detaljer ved eiendommen, noe mange opplever at gir et positivt helhetsinntrykk, sier Lillian Nerlinger.</p>
      <p>På romplakatene kan det eksempelvis stå informasjon om eiendommen, slik som solforhold på uteplassen, når kjøkkenet ble pusset opp og hvilken leverandør det er, eller kostnader for internett og TV.</p>
      <h4>Unik oppfølging av interessentene</h4>
      <p>Hos PrivatMegleren legges det ned stor innsats i å følge opp alle interessentene også etter visningen.</p>
      <p>– Vi følger alltid opp alle som har vært på visning med oppsummering av spørsmål og svar, praktisk informasjon om <a href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig/budrunde">budprosessen</a> og muligheter for finansiering. Basert på kartleggingen av interessentene på visningene har vi god oversikt over hvem som er reelt interessert, slik at vi bruker energien vår målrettet mot de vi tror faktisk kommer til å legge inn bud. Informasjonen om interessentene brukes for å opprettholde interessen og sørge for at budene kommer. Denne skreddersydde oppfølgingen er noe vi får gode tilbakemeldinger på – både fra kjøperne og de som får solgt eiendommen sin, avslutter Lillian Nerlinger.</p>
      `,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode5.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/episodenummer/5.png',
      slug: 'visning',
      link: '/leveransebeskrivelse-selge-bolig/visning'
    },
    {
      id: 6,
      type: ArticlePageType.DEFAULT,
      seo: {
        title: 'Hele salget samlet på ett sted',
        description:
          'Å selge en eiendom innebærer et stort ansvar. Avtaler skal inngås, dokumenter godkjennes, potensielle kjøpere inviteres til visning – og alt må foregå til rett tid og i riktig rekkefølge. Med KVADRAT, en helt ny løsning for samhandling mellom selger og megler, kan du være trygg på at prosessen går slik den skal.'
      },
      card: {
        title: 'KVADRAT',
        description:
          'Å selge en eiendom innebærer et stort ansvar. Avtaler skal inngås, dokumenter godkjennes, potensielle kjøpere inviteres til visning – og alt må foregå til rett tid og i riktig rekkefølge. Med KVADRAT, en helt ny løsning for samhandling mellom selger og megler, kan du være trygg på at prosessen går slik den skal.',
        buttonLabel: 'Til episode'
      },
      title: 'Hele salget samlet på ett sted',
      video: 'https://player.vimeo.com/video/672745973?amp;loop=0&background=0',
      ingress: `
      <p><b>Å selge en eiendom innebærer et stort ansvar. Avtaler skal inngås, dokumenter godkjennes, potensielle kjøpere inviteres til visning – og alt må foregå til rett tid og i riktig rekkefølge. Med KVADRAT, en helt ny løsning for samhandling mellom selger og megler, kan du være trygg på at prosessen går slik den skal.</b></p>
      <p>Når du er i ferd med å gjennomføre den kanskje største handelen i livet, er det betryggende med god assistanse fra fagfolk. Det kan være nervepirrende for mange – salg av en eiendom handler tross alt om store verdier, og det er avgjørende å opprettholde temperaturen på salget hele tiden. Derfor har PrivatMegleren utformet en digital løsning som strømlinjeformer dialogen mellom deg og din megler.</p>`,
      quote:
        'Full oversikt over prosess, statistikk, interessenter og budrunden – rett i lomma',
      content: `
      <h4>Stålkontroll på prosessen</h4><img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode6.jpg" alt="artikkel-bilde" />
      <p>KVADRAT fra PrivatMegleren er en helt ny, digital løsning som samler all informasjon knyttet til salg av eiendom på ett sted. Her har du alltid oversikt over status, og full forutsigbarhet for de neste stegene i prosessen – lett tilgjengelig på telefonen.</p>
      <p>– Med KVADRAT – bokstavelig talt i lomma – har du og jeg hele tiden kontakt med hverandre om både store og små ting, sier eiendomsmegler Lillian Nerlinger ved PrivatMegleren Allé.</p>
      <p>Med lang erfaring som nærmeste rådgiver for eiendomsselgere har hun opparbeidet seg en genuin interesse for å holde tett dialog underveis gjennom alle fasene av eiendomssalg. Hun mener prosessen er bedre for begge når selger og megler blir godt kjent og er trygge på hverandre.</p>
      <p>– Jeg har jevnlige, ofte daglige, telefonsamtaler med alle jeg bistår. Der kan vi diskutere usikkerheter, avklare behov og ellers orientere hverandre om ting som kan være greit å vite. Samtidig er det mye informasjon som ikke alltid egner seg å bare prate om – de fleste setter pris på å ha det skriftlig og lett tilgjengelig, slik at de har en komplett oversikt på ett sted, utdyper hun.</p>
      <h4>Alltid oppdatert og tilgjengelig</h4>
      <p>Alle dokumenter, fra avtaleinngåelse til <a href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig/kontrakt-og-oppgjor">kjøpekontrakt</a>, kan leses, lagres, godkjennes og signeres på ett og samme sted. I KVADRAT får du enkelt og greit varsler om dette underveis. I tillegg får du fortløpende statistikk på antall interessenter, tall på hvor mange som har lastet ned salgsoppgave, hvor mange har meldt seg på <a href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig/visning">visning</a>  og hvor mange som møtte opp. Selger kan også følge budrunden «live».
      </p>
      <p>Dermed kan du under visningen sitte på kafeen rundt hjørnet, og samtidig få informasjon på telefonen om hvor mange som ser på eiendommen din. Eiendomsmegleren forteller at hvor mange som er innom på visning er en god pekepinn for hvor stor interessen er for akkurat din eiendom.</p>
      <p>– Folk synes derfor det er veldig gøy å følge med på hvordan det går underveis, og ha full innsikt i alle fasene av salgsprosessen samtidig som de pågår. </p>
      <p>En av mine viktigste oppgaver er fremdeles å være tilgjengelig for deg, slik at du får en god opplevelse og et salg du blir fornøyd med. Jobben min er å bruke tid på det som gir størst verdi for deg som selger, og det er fortsatt noen oppgaver mennesker gjør bedre enn maskin. Kvadrat er likevel et viktig supplement som sikrer at du som selger alltid har både informasjon og megler lett tilgjengelig, avslutter Nerlinger.</p>
      `,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode6.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/episodenummer/6.png',
      slug: 'kvadrat',
      link: '/leveransebeskrivelse-selge-bolig/kvadrat'
    },
    {
      id: 7,
      type: ArticlePageType.DEFAULT,
      seo: {
        title: 'Godt planlagt budrunde gir resultater',
        description:
          'Det viktigste du gjør som selger under budrunden er å være tilgjengelig, holde hodet kaldt og lytte til gode råd fra megleren. Budrunden er en avgjørende del av ethvert salg av eiendom eller hytte, og det er viktig at alle som er interessert i å kjøpe eiendommen din får delta på like vilkår gjennom en ryddig prosess.'
      },
      card: {
        title: 'Budrunde',
        description:
          'Det viktigste du gjør som selger under budrunden er å være tilgjengelig, holde hodet kaldt og lytte til gode råd fra megleren. Budrunden er en avgjørende del av ethvert salg av eiendom eller hytte, og det er viktig at alle som er interessert i å kjøpe eiendommen din får delta på like vilkår gjennom en ryddig prosess.',
        buttonLabel: 'Til episode'
      },
      title: 'Godt planlagt budrunde gir resultater',
      video: 'https://player.vimeo.com/video/672746019?amp;loop=0&background=0',
      ingress: `
      <p><b>Det viktigste du gjør som selger under budrunden er å være tilgjengelig, holde hodet kaldt og lytte til gode råd fra megleren. Budrunden er en avgjørende del av ethvert salg av eiendom eller hytte, og det er viktig at alle som er interessert i å kjøpe eiendommen din får delta på like vilkår gjennom en ryddig prosess.</b></p>
      <p>I forkant av budrunden vil megleren din gi deg innsikt i hva som skal skje fra deres side, er i ferd med å gjøre, hva du kan forvente av budprosessen, og hva du som selger må ta stilling til underveis. </p>
      <p>Eiendomsmegler Ellen Pike i PrivatMegleren Aksept mener at det beste man kan gjøre når budrunden gjennomføres, er å holde en åpen og god dialog med både budgivere og kjøper. Selve budrunden kan noen ganger oppleves som hektisk, og da er det smart å ha en god plan klar på forhånd, med alternative strategier dersom budrunden tar en annen retning enn planlagt. Det handler om god forventingsstyring.</p>
     <p>– Etter at <a href="https://privatmegleren.no/leveransebeskrivelse/visning">visningen</a> er avholdt har jeg god oversikt over hvem som kan være reelt interessert i å delta i budrunden. Et ganske vanlig scenario er at folk har sett på flere eiendommer samtidig, og er usikre på hvilken de ønsker å by på. Da tar jeg en prat med de ulike interessentene før budrunden starter for å avklare hvor de står, forklarer Ellen Pike.</p>`,
      quote: 'Vær på vakt om en budgiver gir forbehold om finansiering',
      content: `
      <h4>Faste holdepunkter</h4><img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode7.jpg" alt="artikkel-bilde" />
      <p>Hvordan budrundene arter seg varierer fra salg til salg. Det er likevel noen faste holdepunkter man må forholde seg til. Det ene er frister ved budgivning.</p>
      <p>– Tidligste mulige akseptfrist for et bud er klokken 12.00 første virkedag etter siste annonserte visning. Det betyr at interessenter kan gi bud tidligere, men de kan ikke forvente at du som selger aksepterer budet før alle som er interessert har fått mulighet til å delta i budgivningen, forteller Pike.</p>
      <p>Det hender at interessenter ber om en privat visning. Det kan skyldes at de ikke har anledning til å delta på de ordinære visningene eller at de ønsker bedre tid på å bli kjent med eiendommen. Noen ganger er privatvisning et signal om at noen spekulerer i å kjøpe eiendommen før andre interessenter har fått se på den – også kalt kupping. I PrivatMegleren anbefaler vi som hovedregel å ikke akseptere bud som er gitt i forsøk på å kuppe salget. I de aller fleste tilfeller vil du kunne få en høyere pris om hele markedet får være med på en åpen budrunde.</p>
     <p>– Jeg mener det er viktig at man orienterer interessentene om at kupping ikke er ønsket, slik at alle får en forståelse av at selger ønsker en åpen budrunde. Er det likevel noen som forsøker seg – og selger aksepterer det – er det meglers ansvar å sende ut informasjon om det til alle øvrige interessenter så raskt som mulig slik at visningene kan avlyses, sier Ellen Pike.</p>
     <p>I noen tilfeller ligger det regler til grunn som sier at enkelte interessenter kan få anledning til å kjøpe eiendommen etter det som kalles forkjøpsrett – eksempelvis fordi man er medlem i en eiendomsorganisasjon, eller allerede er eier og beboer i sameiet eller borettslaget. Det påvirker budrunden ved at en interessent kan ha høyeste bud, men at eiendommen likevel kan bli solgt til en interessent som ikke har deltatt i budrunden. Det er derfor alltid lurt at selger og megler har snakket om muligheten for dette før visningene og budrunden.</p> 
     <h4>Forbehold og fleksibilitet</h4>
     <p>Det er også viktig å være oppmerksom på forbehold ved budgivningen, særlig når det gjelder finansiering. Aller helst vil man ha bud med færrest mulig forbehold. Noen forbehold er likevel lettere å forholde seg til enn andre, eksempelvis dersom budgiver ønsker terrassemøbler eller annet løsøre med på kjøpet. Det er slikt det i de fleste tilfeller går an å forhandle seg frem til en enighet om. </p>
      <p>Det er lurt å på forhånd ha tenkt gjennom hvor fleksibel man som selger kan være. Det kan for eksempel være smart å ikke være for standhaftig når det gjelder tidspunkt for overtakelse, så lenge du får salgssummen du ønsker</p>
      <p>Man bør derimot være mer på vakt om en budgiver gir forbehold om finansiering. Det betyr i så fall at vedkommende ikke har avklart med sin bank at man kan bruke så mye penger på kjøp av eiendom. Meglerne i PrivatMegleren har imidlertid god erfaring med å håndtere slikt, og vil gi gode råd til både budgiver og selger underveis.</p>
      <p>Det kan en sjelden gang skje at det ikke blir lagt inn bud etter visning. Årsakene kan være mange. Dersom det skjer er jobben vår å kartlegge hvorfor det ikke kommer bud, og finne ut av hvordan vi skal løse det. Sammen med selger finner vi som regel en løsning som gjør at eiendommen blir solgt, for eksempel ved at man justerer prisantydningen, legger den ut i markedet til en annen tid på året, eller gjør oppgraderinger som gjør den mer attraktiv. Vi i PrivatMegleren følger uansett deg som selger hele veien fra start til salg og <a href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig/overtakelse">overtakelse</a>.</p> 
     `,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode7.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/episodenummer/7.png',
      slug: 'budrunde',
      link: '/leveransebeskrivelse-selge-bolig/budrunde'
    },
    {
      id: 8,
      type: ArticlePageType.DEFAULT,
      seo: {
        title:
          'Tilrettelegger for en smidig overdragelse (Kontrakt og oppgjør)',
        description:
          'En eiendom er formelt solgt i øyeblikket selger har gitt aksept til et bud. Det er likevel vanlig at selger og kjøper møter hverandre i et kontraktsmøte i etterkant – digitalt eller fysisk – for å formalisere eierskiftet og gå gjennom detaljene for salget sammen med megleren.'
      },
      card: {
        title: 'Kontrakt og oppgjør',
        description:
          'En eiendom er formelt solgt i øyeblikket selger har gitt aksept til et bud. Det er likevel vanlig at selger og kjøper møter hverandre i et kontraktsmøte i etterkant – digitalt eller fysisk – for å formalisere eierskiftet og gå gjennom detaljene for salget sammen med megleren.',
        buttonLabel: 'Til episode'
      },
      title: 'Tilrettelegger for en smidig overdragelse (Kontrakt og oppgjør)',
      video: 'https://player.vimeo.com/video/672746068?amp;loop=0&background=0',
      ingress: `
      <p><b>En eiendom er formelt solgt i øyeblikket selger har gitt aksept til et bud. Det er likevel vanlig at selger og kjøper møter hverandre i et kontraktsmøte i etterkant – digitalt eller fysisk – for å formalisere eierskiftet og gå gjennom detaljene for salget sammen med megleren.</b></p>
      <p>Samme dag som <a href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig/budrunde">budet</a> er akseptert sender megler ut et akseptbrev til partene, med detaljene om salget, samt utkast til kjøpekontrakt. På kontraktsmøtet signerer partene kontrakten, og går gjennom detaljer for oppgjør og <a href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig/overtakelse">overtakelse</a>.</p>
      <p>Eiendomsmegler Christian Stenerud i PrivatMegleren Panorama forteller at kontraktsmøtet er en fin anledning for selger og kjøper å møtes og bli litt kjent med hverandre</p>
      <p>– Mange opplever kontraktsmøtet som en stor og høytidelig livshendelse, og da er det fint at vi som meglere kan skape en hyggelig atmosfære hvor partene kan prate om stort og smått rundt eiendommen, forteller Stenerud.</p>
      <p>Det er fornuftig med en felles gjennomgang slik at alle forstår avtalen likt, og at megler kan svare på eventuelle spørsmål som dukker opp. I forbindelse med formalisering av salget fyller selger og kjøper også ut et oppgjørsskjema, med opplysninger som kjøpesum og bankkontonummer. Alle dokumentene knyttet til salget er på forhånd gjort tilgjengelig for selger i <a href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig/kvadrat">Kvadrat</a></p>
      <p>Stenerud forklarer at man i løpet av kontraktsmøtet også pleier å avtale tidspunkt for overtakelse av eiendommen. </p>
      <p>– Det vanlige er at overtakelsen finner sted to til fire måneder etter salget, men her står man fritt til å avtale at eiendommen kan overtas tidligere eller senere, alt etter hva som passer best, sier Stenerud.</p>
      <p>Fra kontraktsmøtet er gjennomført til overtakelsen finner sted skal eiendommen klargjøres for neste eier. Selger må sørge for at møbler og løsøre samsvarer med inventarlisten, og at det er utført en grundig <a href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig/boligbytte">flyttevask</a>. Det er for øvrig ingenting i veien for at selger og kjøper har dialog med hverandre i tiden frem mot overtakelsen når kontakten først er opprettet.</p>`,
      quote:
        'Det vanlige er at overtakelsen finner sted to til fire måneder etter salget',
      content: `
      <h4>En stor handel bør alltid forsikres</h4><img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode8.jpg" alt="artikkel-bilde" />
      <p>Mange lurer på om de behøver forsikring i forbindelse med kjøp og salg av eiendom. For mange er dette kanskje den største handelen man gjør i livet, og det er naturligvis store verdier involvert. Både eiendomsselgerforsikring og eiendomskjøperforsikring fungerer som en helgardering mot uforutsette ting, slik at dine verdier er ivaretatt uansett hva som skjer.</p>
      <p>Det kan oppleves som ubehagelig om det kommer en reklamasjonssak i etterkant av salget. Dersom det etter overtakelse skulle vise seg å dukke opp noe som kjøper opplever som en mangel eller feil som hverken du som selger eller takstmann har opplyst om, slipper du med eiendomsselgerforsikring å bruke mye tid og krefter på å følge opp reklamasjonssaken selv.</p>
      <p>– Det er ryddig å kunne ta kontakt med megler og forsikringsselskap om noe er ugreit. En av de største fordelene er at forsikringsselskapene fører saken direkte seg imellom. Forsikringsselskapene vet hvilke regler som gjelder, og har god kjennskap til hvordan liknende saker er løst tidligere, avslutter Christian Stenerud.</p> 
     `,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode8.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/episodenummer/8.png',
      slug: 'kontrakt-og-oppgjor',
      link: '/leveransebeskrivelse-selge-bolig/kontrakt-og-oppgjor'
    },
    {
      id: 9,
      type: ArticlePageType.DEFAULT,
      seo: {
        title: 'Tilrettelegger for et bekvemt Boligbytte',
        description:
          'Gleden ved en ny eiendom eller hytte kan lett overskygges av alt det praktiske arbeidet som følger med flytteprosessen. For at overgangen skal være så bekvem som mulig for deg har PrivatMegleren utviklet tjenesten Boligbytte, slik at du i stedet kan bruke energi på alt det gledelige ved å komme i stand på et nytt sted.'
      },
      card: {
        title: 'Boligbytte',
        description:
          'Gleden ved en ny eiendom eller hytte kan lett overskygges av alt det praktiske arbeidet som følger med flytteprosessen. For at overgangen skal være så bekvem som mulig for deg har PrivatMegleren utviklet tjenesten Boligbytte, slik at du i stedet kan bruke energi på alt det gledelige ved å komme i stand på et nytt sted.',
        buttonLabel: 'Til episode'
      },
      title: 'Tilrettelegger for et bekvemt Boligbytte',
      video: 'https://player.vimeo.com/video/672746106?amp;loop=0&background=0',
      ingress: `
      <p><b>Gleden ved en ny eiendom eller hytte kan lett overskygges av alt det praktiske arbeidet som følger med flytteprosessen. For at overgangen skal være så bekvem som mulig for deg har PrivatMegleren utviklet tjenesten Boligbytte, slik at du i stedet kan bruke energi på alt det gledelige ved å komme i stand på et nytt sted.</b></p>
      <p>Det er mye å huske på når du skal flytte til en ny eiendom. Selve flyttingen innebærer å frakte alle dyrebare eiendeler trygt fra et sted til et annet. Møbler og inventar skal pakkes forsvarlig, transporteres og kanskje mellomlagres. Det sier seg selv at det blir mange tunge løft før du er på plass.</p>
      <p>Det er også mye mer enn å flytte eiendeler og adresseendring som skal ordnes før du er klar til en ny hverdag på et nytt sted. Nye avtaler for strøm og bredbånd skal opprettes, mens de gamle skal avsluttes. Har du solgt eller tidligere leid eiendom må du som regel også sørge for en grundig flyttevask.</p>`,
      quote:
        'Boligbytte kan benyttes allerede i forbindelse med visning, hvor du for eksempel kan få hjelp med visningsvask og mellomlagring av inventar',
      content: `
      <h4>Gir deg mer tid og overskudd</h4><img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode9.jpg" alt="artikkel-bilde" />
      <p>Det er ikke til å legge skjul på at det for mange kan være utfordrende å koordinere hele flytteprosessen – særlig i en hektisk familiehverdag. For at overgangen for deg og din familie skal være enkel å håndtere tilbyr PrivatMegleren tjenesten Boligbytte – en komplett flyttetjeneste, hvor du selv velger hva du ønsker bistand med.</p>
      <p>PrivatMeglerens medarbeidere som jobber med Boligbytte kan hjelpe til med det som skal til for at du skal slippe alt styret med flyttingen. Ofte kan du få hjelp til mer enn du tror. Formålet er rett og slett å gjøre flytteprosessen så bekvem som mulig for deg.</p>
      <p>Boligbytte er kostnadsfri å bruke. Her kan du hente tilbud fra våre samarbeidspartnere som kan hjelpe deg med alt det praktiske i forbindelse med flyttingen. Slik kan du selv velge hva du vil ha bistand med og spare tid og krefter – tid og overskudd du heller kan bruke på familie og fritid.</p> 
      <h4>Trygt og praktisk</h4>
      <p>Boligbytte kan benyttes allerede i forbindelse med visning, hvor du for eksempel kan få hjelp med visningsvask og mellomlagring av inventar. Det er praktisk om du har eiendeler som ikke skal være med på visningen. Når du selger eller kjøper eiendom gjennom PrivatMegleren kan du sammen med megler legge en plan om alt det praktiske rundt flyttingen, og overlate til Boligbytte å sørge for en velkoordinert og knirkefri overgang til din nye eiendom.</p>
      <p>En sjelden gang kan uhell skje, og mange tenker ikke på at ting kan gå i stykker når de flyttes fra et sted til et annet. Derfor anbefaler Privatmegleren at både selger og kjøper vurderer boligbytte-forsikring.</p>
      `,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode9.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/episodenummer/9.png',
      slug: 'boligbytte',
      link: '/leveransebeskrivelse-selge-bolig/boligbytte'
    },
    {
      id: 10,
      type: ArticlePageType.DEFAULT,
      seo: {
        title: 'Følger deg helt i mål',
        description:
          'Overtakelsen er en viktig – og som regel veldig hyggelig – seanse i en eiendomshandel, der selger skal overlevere eiendommen til dens nye eier. I PrivatMegleren deltar vi alltid ved overtakelse for å sørge for at ingenting blir glemt eller utelatt i det siste steget av salgsprosessen.'
      },
      card: {
        title: 'Følger deg helt i mål',
        description:
          'Overtakelsen er en viktig – og som regel veldig hyggelig – seanse i en eiendomshandel, der selger skal overlevere eiendommen til dens nye eier. I PrivatMegleren deltar vi alltid ved overtakelse for å sørge for at ingenting blir glemt eller utelatt i det siste steget av salgsprosessen.',
        buttonLabel: 'Til episode'
      },
      title: 'Følger deg helt i mål',
      video: 'https://player.vimeo.com/video/672746152?amp;loop=0&background=0',
      ingress: `
      <p><b>Overtakelsen er en viktig – og som regel veldig hyggelig – seanse i en eiendomshandel, der selger skal overlevere eiendommen til dens nye eier. I PrivatMegleren deltar vi alltid ved overtakelse for å sørge for at ingenting blir glemt eller utelatt i det siste steget av salgsprosessen.</b></p>
      <p>Tidspunktet for overtakelsen blir som oftest avtalt ved <a href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig/kontrakt-og-oppgjor">kontraktsmøtet</a> som blir avholdt kort tid etter at budet på eiendommen er  akseptert. Ved overtakelsen går selger og kjøper gjennom eiendommen sammen, og kontrollerer at den samsvarer med det som er avtalt. Om det skulle dukke opp spørsmål eller uenighet, er megleren der for å bidra til å finne løsninger.</p>
      <p>– Overtakelsen dreier seg mye om rent praktiske ting, som for eksempel at nøklene til eiendommen overleveres til ny eier. I tillegg skal strømmåleren leses av og selger kan orientere om eksempelvis bruk av tekniske løsninger i bygget – som smarthusstyring av lys og strøm, forklarer eiendomsmegler Christian Stenerud i PrivatMegleren Panorama.</p>
      <p>Når alt er gjennomgått og kontrollert signerer partene overtakelsesprotokoll og eiendommen er dermed overtatt av ny eier.</p>`,
      quote:
        'Selger plikter å overlevere eiendommen i den tilstand den var i ved salgstidspunktet',
      content: `
      <h4>Selgers og kjøpers plikter</h4><img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode10.jpg" alt="artikkel-bilde" />
      <p>Som selger plikter man å gjøre eiendommen klar for overtakelse, ved at den er ryddet og rengjort. Flyttevask skal være gjort, og eiendeler på eiendommen skal samsvare med inventarlisten – med mindre noe annet er avtalt. Ellers plikter også selger å overlevere eiendommen i den tilstand den var i ved salgstidspunktet.</p>
      <p>Kjøper på sin side plikter å gjøre opp for seg til meglers klientkonto før overtakelsen. Etter at overtakelsesprotokollen er signert, og overdragelsen av eiendommen blir tinglyst, overføres kjøpesummen til selger. Ut over det plikter kjøper også til å overta eiendommen ved signering av protokoll, og har dermed videre ansvar for løpende kostnader, at eiendommen er forsikret og alle andre forpliktelser som følger. </p>
      <p>– I tilfeller der kjøper og selger har ulike standarder for om flyttevasken er godt nok utført, har kjøper anledning til å holde igjen penger tilsvarende kostnaden for ny flyttevask. Det er slike ting megler er til stede for å hjelpe partene med å bli enige om, avslutter Christian Stenerud.</p>
      `,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode10.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/episodenummer/10.png',
      slug: 'overtakelse',
      link: '/leveransebeskrivelse-selge-bolig/overtakelse'
    },
    {
      id: 11,
      type: ArticlePageType.DEFAULT,
      seo: {
        title: 'KJØP med en profesjonell sparringpartner',
        description:
          'Det må som regel investeres mye tid og innsats før du ender opp med den perfekte eiendommen. Med tjenesten KJØP fra PrivatMegleren får du bistand fra en profesjonell sparringpartner – slik sparer vi deg for tid og hjelper deg med å unngå feilkjøp.'
      },
      card: {
        title: 'KJØP',
        description:
          'Det må som regel investeres mye tid og innsats før du ender opp med den perfekte eiendommen. Med tjenesten KJØP fra PrivatMegleren får du bistand fra en profesjonell sparringpartner – slik sparer vi deg for tid og hjelper deg med å unngå feilkjøp.',
        buttonLabel: 'Til episode'
      },
      title: 'KJØP med en profesjonell sparringpartner',
      video: 'https://player.vimeo.com/video/672746197?amp;loop=0&background=0',
      ingress: `
      <p><b>Det må som regel investeres mye tid og innsats før du ender opp med den perfekte eiendommen. Med tjenesten KJØP fra PrivatMegleren får du bistand fra en profesjonell sparringpartner – slik sparer vi deg for tid og hjelper deg med å unngå feilkjøp.</b></p>
      <p>I mylderet av annonser, tilstandsrapporter og visninger kan det lett oppstå både usikkerhet og hodebry – særlig om du skal navigere i markedet på egenhånd. For at du skal kunne gjøre solide avveininger er det avgjørende med gode råd og støtte underveis i kjøpsprosessen. Eiendomsmegler Christian Stenerud mener av den grunn at tjenesten <a href="https://privatmegleren.no/kjope-eiendom">KJØP</a> fra PrivatMegleren har en rekke fordeler.</p>
      <p>– KJØP handler i all enkelhet om å gi deg som kjøper gode råd hele veien – helt fra du starter å lete til du står med nøkkelen til din nye eiendom i hånden. For å kunne gjøre et godt og veloverveid kjøp av eiendom er det mye du må sette deg inn i. Da føles det trygt å ha en profesjonell sparringpartner med deg på veien, slik at du sikrer å gjøre et godt kjøp, sier Christian Stenerud i PrivatMegleren Panorama.</p>
      <p>Stenerud anbefaler å ta kontakt allerede idet du begynner å tenke på å kjøpe eiendom. Som med mye annet gir gode forberedelser bedre resultater. Den største fordelen ved å få profesjonell hjelp i eiendomsjakten er at du ender opp med et kjøp du virkelig er fornøyd med. Å kjøpe en eiendom er tross alt noe av det viktigste man gjør i livet.</p>`,
      quote:
        'Mangler i seg selv trenger ikke å være avskrekkende, men det gjelder å vite hva man skal se etter',
      content: `
      <h4>Et kritisk blikk og profesjonelle råd</h4><img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode11.jpg" alt="artikkel-bilde" />
      <p>Med KJØP kan du velge mellom de to nivåene Basis og Premium. Basis innebærer at du selv leter etter eiendommer som kan være aktuelle for deg, hvor megler går gjennom salgsoppgaven før visning og hjelper deg med å vurdere hvilke som er verd å bruke mer tid på. Dette innebærer at du får du en profesjonell vurdering av salgsoppgave og tilstandsrapport – lest profesjonelt og nøytralt av noen som kjenner markedet godt, og vet hva de skal se etter.</p>
      <p>– Det er tidkrevende å sette seg godt inn i salgsoppgaven og å ta inn over seg hva takstmannens vurdering innebærer. Som eiendomsmeglere er vi trente i å både identifisere eiendommens fordeler og mangler. I tillegg har en megler også kunnskap og erfaring med å vurdere hva som er kostbart å utbedre og hva som er rene bagateller. Mangler i seg selv trenger ikke å være avskrekkende, men det gjelder å vite hva man skal se etter, forklarer Stenerud.</p>
      <p>Før budrunden får du også konkrete og taktiske råd om hva du bør gjøre, samtidig som du kan sparre med megler underveis i prosessen. </p>
      <h4>Tilgang før alle andre</h4>
      <p>Med Premium-nivået av KJØP vil eiendomsmegler lete aktivt etter aktuelle eiendommer for deg, basert på en grundig analyse av dine behov og kriterier. Det mange kanskje ikke vet er at mange eiendommer og hytter omsettes utenom det åpne markedet. For å finne eiendommen som samsvarer med dine behov bruker derfor PrivatMegleren hele sitt nettverk og tilgjengelige data i søket.</p>

      <p>Eiendomsmeglere har kjennskap til mange eiendommer lenge før de kommer for salg, og eiendommer som ikke annonseres i det åpne markedet. Vi kan også gå aktivt ut i områder som er av interesse for å finne potensielle eiendommer som kan passe dine behov – til og med gå på befaring for å avklare om det faktisk er riktig eiendom for deg. Om du er på jakt etter noe helt spesielt, kan PrivatMegleren på denne måten gjøre prosessen med å finne din neste eiendom eller hytte langt mer effektiv.</p>
      <p>På visningen er megler med deg for å vurdere det dere vil undersøke nærmere, alt basert på en nøye sammensatt plan utarbeidet på forhånd. Slik får du en best mulig kvalifisert vurdering av prisantydningen og den reelle verdien av eiendommen. Det gir deg et godt fundament før budrunden, hvor megler også gir deg gode råd og hjelp til å velge riktig taktikk underveis. Dersom du ønsker, kan megler også håndtere budrunden i sin helhet for deg.</p>
      <p>– Det er spesielt hyggelig å kunne hjelpe folk videre i livet, til der de skal være. For å få til det bruker vi all vår kunnskap og erfaring til å gi deg gode råd om når du bør ha is i magen eller når du bør agere raskt, avslutter Christian Stenerud.</p>
      `,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/artikkelbilder/episode11.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/episodenummer/11.png',
      slug: 'kjop',
      link: '/leveransebeskrivelse-selge-bolig/kjop'
    },
    {
      id: 100,
      type: ArticlePageType.LEISURE,
      seo: {
        title: 'Høy lokalkunnskap er viktig for kjøperen',
        description:
          'Stegene man går gjennom for å selge en fritidseiendom på fjellet eller ved sjøen har flere likhetstrekk med salg av annen eiendom. Sammenlignet med et eiendomssalg er det imidlertid store forskjeller ved prosessen kjøperen går gjennom. Forskjeller det er viktig å vite om når du skal velge megler.'
      },
      card: {
        title: 'Høy lokalkunnskap',
        description:
          'Stegene man går gjennom for å selge en fritidseiendom på fjellet eller ved sjøen har flere likhetstrekk med salg av annen eiendom. Sammenlignet med et eiendomssalg er det imidlertid store forskjeller ved prosessen kjøperen går gjennom. Forskjeller det er viktig å vite om når du skal velge megler.',
        buttonLabel: 'Les mer'
      },
      title: 'Høy lokalkunnskap er viktig for kjøperen',
      ingress: `
      <p><b>Stegene man går gjennom for å selge en fritidseiendom på fjellet eller ved sjøen har flere likhetstrekk med salg av annen eiendom. Sammenlignet med et eiendomssalg er det imidlertid store forskjeller ved prosessen kjøperen går gjennom. Forskjeller det er viktig å vite om når du skal velge megler.</b></p>
      <p>Ved salg av fritidseiendom er det en avgjørende suksessfaktor at megleren kjenner området like godt som en lokalkjent. Det er nemlig ikke uvanlig at de som kommer på visning ikke er så godt kjent i området, og derfor er avhengig av å få kunnskap av noen som kjenner området godt. Eiendomsmegler Pia M. Murland i PrivatMegleren Lillehammer forteller at potensielle kjøpere ikke bare er interessert i hytta de ser på visning, men også nærområdet.</p>
      <p>– Som megler må jeg være oppdatert på alt som skjer i nærområdet. Det handler om å kjenne til alle fordelene og mulighetene området har å by på, slik at kjøper kan bli godt kjent med nærmiljøet på kort tid. Interesseområdet for potensielle kjøpere av en hytte på fjellet er bredt og spenner over alt fra turmuligheter, fiskevann og badesteder, til arrangementer, samlinger og after-ski, forklarer eiendomsmegler Pia.</p>
      <br><br><br>
      `,
      content: `
      <h4>Byr på detaljene</h4><img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/fritid/Artikkelbilde_1.jpg" alt="artikkel-bilde" />
      <p>Pia sammenligner jobben som megler med å være en ambassadør for området. Ofte er hun innom de lokale kafeene eller slår av en prat med brøytebilsjåførene for å få siste nytt. Kunnskapen hun tilegner seg om nærområdet er ofte på et forbausende detaljnivå.</p>
      <p>Eiendomsmegler Gustav J. Sædberg i PrivatMegleren Sædberg og Lian kjenner seg godt igjen i Pias beskrivelse. Selv om fritidseiendommene han er megler for som regel ligger ved sjøen, forteller han at lokalkunnskapen er like viktig i skjærgården.</p>
      <p>– Også ved sjøen finnes det spesielle steder – gode badeplasser, fiskesteder og hvor du kan være sikker på å finne krabber. Siden vi ofte er avhengig av båt for å komme ut til eiendommene tar vi oss gjerne tid til å vise frem det som finnes nærområdet. Som megler må man også tørre å by på seg selv, og fortelle hvordan man selv bruker skjærgården, forteller Gustav.</p>
      <h4>Lokalkunnskap som spisskompetanse</h4>
      <p>Gustav peker imidlertid på at byggeforbudet i strandsonen trekker et viktig skille mellom fjell og sjø. Forbudet innebærer at det ikke bygges nye fritidseiendommer nærmere enn 100 meter fra sjøen.</p>
      <p>– En del av vår lokalkunnskap er også å vite hva som er tillatt å gjøre på eiendommen. Derfor har vi spisskompetanse på regelverk og søknadsprosesser i den enkelte kommune, samt hvilke endringer man kan få tillatelse til å gjøre, forklarer Gustav.</p>
      <h4>Nærområdet er avgjørende</h4>
      <p>Både Pia og Gustav forteller at de som vurderer å kjøpe en fritidseiendom gjerne ser på færre eiendommer enn ved for eksempel et eiendomskjøp.</p>
      <p>– Det er gjerne større følelser knyttet til kjøp av fritidseiendom enn ved kjøp av eiendom. Kjøperne «tar og føler» mer på eiendommen, og spør gjerne mer om nærområdet enn om selve hytta. Da er det avgjørende at megler både har god lokalkunnskap og får formidlet alle fordelene ved området, sier Gustav.</p>
      <p>På visningen benytter PrivatMegleren romplakater for å trekke frem nyttig informasjon om en eiendom, slik som takhøyde, kjøkkenfabrikat eller fortelle at et rom har gulvvarme. Pia forteller at romplakatene også aktivt brukes til å fortelle om nærområdet.</p>
      <p>– På romplakatene kan vi for eksempel vise kart over skiløyper og turstier i området, eller kanskje åpningstidene til alpinbakken og den lokale butikken. Slik blir det lettere for kjøperne å orientere seg og bli kjent med nærmiljøet, forklarer Pia, mens Gustav legger til:</p>
      <p>– For hytter ved sjøen kan vi vise vei til en fin badestrand på naboøya, eller gi praktiske opplysninger om hvor det er parkeringsplasser på fastlandet. Enten hytta ligger på fjellet eller ved sjøen er romplakatene et fint virkemiddel for å fremheve nyttig informasjon om de nære tingene som kjøperne er opptatte av, utdyper Gustav.</p>
      `,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/fritid/Toppbilde_1.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/kvadrat_fritid.png',
      slug: 'hoy-lokalkunnskap',
      link: '/leveransebeskrivelse-selge-bolig/fritid/hoy-lokalkunnskap'
    },
    {
      id: 101,
      type: ArticlePageType.LEISURE,
      seo: {
        title: 'Fremhever kvalitetene som oppfyller drømmen om hytte',
        description:
          'For de som vil realisere hyttedrømmen er mulighetene for både aktiviteter og rekreasjon vel så viktig som selve hytta. Det er derfor viktig med et grundig forarbeid for at fritidseiendommen skal appellere til flest mulig.'
      },
      card: {
        title: 'Fremhever kvalitetene',
        description:
          'For de som vil realisere hyttedrømmen er mulighetene for både aktiviteter og rekreasjon vel så viktig som selve hytta. Det er derfor viktig med et grundig forarbeid for at fritidseiendommen skal appellere til flest mulig.',
        buttonLabel: 'Les mer'
      },
      title: 'Fremhever kvalitetene som oppfyller drømmen om hytte',
      ingress: `
      <p><b>For de som vil realisere hyttedrømmen er mulighetene for både aktiviteter og rekreasjon vel så viktig som selve hytta. Det er derfor viktig med et grundig forarbeid for at fritidseiendommen skal appellere til flest mulig.</b></p>
      <p>De erfarne eiendomsmeglerne Pia M. Murland og Gustav J. Sædberg forteller at fritidseiendom står sterkt i befolkningen. Samtidig understreker de at fritidseiendom ikke er et grunnleggende behov på samme måte som en eiendom – det er rett og slett ikke noe man må ha. Derfor er det viktig å skape den riktige følelsen hos kjøperen, og fremheve hvordan fritidseiendom du selger kan oppfylle drømmen.</p>
      <br><br><br>
      `,
      content: `
      <h4>Legger et godt grunnlag på befaringen</h4><img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/fritid/Artikkelbilde_2.jpg" alt="artikkel-bilde" />
      <p>Meglerne opplever at de fleste hytteeiere er ute i god tid før de skal selge. Det er en fordel, fordi det gir bedre tid til å sørge for at alt er på stell etter meglers befaring. Hvordan befaringen gjennomføres avhenger ofte av beliggenheten.</p>
      <p>– Mange befaringer på fjellet foretas uten at selger er til stede, særlig om selger har lang reisevei. Rent praktisk får megler da tilgang til nøkkel, og har kontakt med selger på telefon om det er behov, sier Pia M. Murland i PrivatMegleren Lillehammer.</p>
      <p>Hennes kollega Gustav forteller at det som regel er motsatt for hytter ved sjøen.</p>  
      <p>– Om hytta ligger i skjærgården er selger ofte med på befaringen, fordi man i større grad er avhengig av båt for å komme seg ut til eiendommen. Det er også nyttig at selger kan fortelle om det nære ved hytta og hvordan de har brukt eiendommen, forteller eiendomsmegler Gustav J. Sædberg i PrivatMegleren Sædberg & Lian.</p>
      <p>Basert på funnene fra befaringen vil megleren gi deg anbefaling om hvilke grep man kan ta for å vise eiendommen fra sin beste side. De erfarne meglerne er samstemte om at det vanligvis er god nok tid til å prioritere utbedringer og sørge for å få alle detaljene på plass, ettersom mange selgerne planlegger godt for salget.</p>
      <p>– Godt vedlikehold er et kvalitetstegn for kjøperne, og får de ikke den riktige følelsen kan de nærmest snu i døra. Selv små grep kan gjøre at hytta appellerer til flere. Om man får med seg en ekstra interessent inn i budrunden har det mye å si for den endelige prisen, forklarer Pia.</p> 
      <p>– Detaljene handler også om mer enn det visuelle. Det kan for eksempel finnes muntlige avtaler om slikt som parkeringsplass og båtplass, som bør formaliseres og nedtegnes skriftlig før salget. Da er det greit å ha god tid til papirarbeidet, utdyper Gustav.</p>
      <h4>Fremhever bruken</h4>
      <p>I presentasjonen av fritidseiendommen jobber meglerne aktivt for å gjenspeile hyttedrømmen. Også her finnes det forskjeller mellom hytter ved sjøen og på fjellet. Gustav trekker frem som eksempel at mange har et ønske om å være aktiv på hytter ved sjøen.</p>
      <p>– Vi tilrettelegger slik at man får lyst til å bruke eiendommen med en gang, gjennom å vise frem bruken. Mange vil være aktive sammen med barna. Det er her barna lærer seg å svømme, fange krabber fra bryggekanten og drar på fisketurer sammen med de voksne. Da tar det seg bra ut vi har rigget båten klar ved bryggekanten, og redningsvestene henger klare i sjøboden, forklarer han.</p>
      <p>Også på fjellet er det mange aktiviteter for store og små utenfor hyttedøra. Pia forteller imidlertid at potensielle kjøpere her oftest ser etter et rolig sted, med en varm, lun og koselig stemning innendørs.</p>
      <p>– Det handler om å gi følelsen av at man kan trives der, bare fra å se på bildene. Hvert rom forberedes derfor godt, slik at ingenting er overlatt til tilfeldighetene. Sengene er redd opp med sengeteppe, det er rent, ryddig og lys i vinduene. Pleddet ligger klar foran peisen, og alt er klargjort for ro og avslapning, forteller Pia.</p>
      <p>Når hytta er klargjort for å vises frem fra sin beste side fanger PrivatMeglerens håndplukkede fotografer den gode stemningen på foto til bruk i annonseringen – også fra fugleperspektiv.</p>
      <p>– Vi bruker ofte dronebilder for å gi riktig inntrykk av beliggenheten, størrelsen på eiendommen og avstand til ting i området. Et utvalg av informative symboler brukes for å merke av steder og aktiviteter i området, og gjør det lettere for interessenter å plassere hytta i nærområdet, sier Pia.</p>
      <h4>Visning med hvilepuls</h4>
      <p>Når eiendommen er gjort klar for å presenteres for markedet er det duket for visning. Meglerne forteller at det er en høyere andel privatvisninger av hytter enn ved salg   av eiendom</p>
      <p class="Paragraph__StyledParagraph-sc-1mht3ex-0 fVANPY">– Folk er mye mer avslappet på hyttevisninger, og stiller gjerne i fritidsklær. Ofte kombinerer folk visningen med en utflukt i nærheten, slik at de kan bli bedre kjent i området. Det er ikke uvanlig at de som kommer på visning ikke har vært på stedet før. Da er det en fordel om megler har høy kunnskap om alt som rører seg i <a href="https://privatmegleren.no/kampanje/fritidsartikkel">nærmiljøet</a>, forklarer Pia.</p>
      <p>– Vi opplever en del flere andregangsvisninger av fritidseiendom, hvor man kanskje også har med seg håndverker. I skjærgården må fellesvisning av hytter planlegges godt, blant annet fordi man må sørge for noen som kjører båt frem og tilbake, legger Gustav til.</p>
      <p>Selv om det før visning er viktig å gjøre hytta nøytral for flest mulig vil små grep kunne forsterke idyllen kjøperne ser for seg.</p>
      <p>– Det er gjerne de små tingene som teller. At skiene står utenfor og fyr i peisen, bidrar til den lune stemningen. I påsketiden er det gjerne pyntet med påskeliljer, appelsiner og nytraktet kaffe. Stemningen når man da kommer ut på verandaen i solskinnet er ubetalelig, beskriver Pia.</p>
      `,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/fritid/Toppbilde_2.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/kvadrat_fritid.png',
      slug: 'fremhever-kvaliteten',
      link: '/leveransebeskrivelse-selge-bolig/fritid/fremhever-kvaliteten'
    },
    {
      id: 102,
      type: ArticlePageType.LEISURE,
      seo: {
        title: 'Spesialister for enhver pris',
        description:
          'Livet på hytta tilbringes gjerne i roligere tempo enn hverdagslivet hjemme. Sammenlignet med eiendomsmarkedet er det også ofte mindre hektisk ved salg av fritidseiendom, ettersom omsetningen av hytter i større grad følger årstidene. Å ta seg god tid kan lønne seg.'
      },
      card: {
        title: 'Spesialister for enhver pris',
        description:
          'Livet på hytta tilbringes gjerne i roligere tempo enn hverdagslivet hjemme. Sammenlignet med eiendomsmarkedet er det også ofte mindre hektisk ved salg av fritidseiendom, ettersom omsetningen av hytter i større grad følger årstidene. Å ta seg god tid kan lønne seg.',
        buttonLabel: 'Les mer'
      },
      title: 'Spesialister for enhver pris',
      ingress: `<p><b>Livet på hytta tilbringes gjerne i roligere tempo enn hverdagslivet hjemme. Sammenlignet med eiendomsmarkedet er det også ofte mindre hektisk ved salg av fritidseiendom, ettersom omsetningen av hytter i større grad følger årstidene. Å ta seg god tid kan lønne seg.</b></p>`,
      content: `
      <img class="inline-image" src="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/fritid/Artikkelbilde_3.jpg" alt="artikkel-bilde" />
      <p>Eiendomsmegler Gustav J. Sædberg i PrivatMegleren Sædberg & Lian kjenner godt til hyttemarkedet i skjærgårde</p>
      <p>– Hyttene og skjærgården viser seg naturligvis fra sin beste side i perioden fra påsketider til høstferien. Slikt sett kan hytter ved sjøen oppleves som sesongvare. Fordi hytte ikke er noe man må ha, men gjerne vil ha, er min erfaring at mange tar seg god tid når de ser etter et sted å tilbringe fritiden i årene fremover, beskriver Gustav.</p>
      <p>Tiden en fritidseiendom ligger tilgjengelig i markedet kan variere. I de store hyttefeltene, der hyttene ofte er mer like hverandre, bør man være forberedt på at det kan ta lengre tid å gjennomføre et salg. En hytte med unik utforming og plassering, egenskaper som kan oppfattes som et knapphetsgode, er derimot ofte mer ettertraktet og selges på kortere tid.</p>
      <p>Pia M. Murland er eiendomsmegler i PrivatMegleren Lillehammer, og er spesialist på salg av hytter på fjellet. Hun legger til at det er flere årsaker til at hytter ofte ikke selges like raskt som eiendommer.</p>
      <p>– Å dra på visning av hytte på fjellet eller ved sjøen er for de fleste mer omfattende enn å gå på visning av en ny eiendom i samme by. På grunn av avstanden mellom hjemmet og hyttene man vil se på, planlegger gjerne kjøpere hyttevisninger med en lengre tidshorisont, forteller hun.</p>`,
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/fritid/Toppbilde_3.jpg',
      imageOverlay:
        'https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/kvadrat_fritid.png',
      slug: 'spesialister',
      link: '/leveransebeskrivelse-selge-bolig/fritid/spesialister'
    }
  ];

  const getArticlePageType = () => {
    switch (params.type) {
      case 'fritid':
        return ArticlePageType.LEISURE;
      default:
        return ArticlePageType.DEFAULT;
    }
  };

  const getMain = () => {
    return main.find((item) => item.pathname === location.pathname) || null;
  };

  const getFirstArticle = () => {
    const type = getArticlePageType();
    return articles.filter((item) => item.type === type)[0];
  };

  /* Picking out all episodes that is not of id 0 */
  const getArticles = (args: GetArticlesFilterArgs) => {
    const type = getArticlePageType();
    const filtered = articles.filter((item) => item.type === type);
    if (args?.excludeFirst) {
      filtered.splice(0, 1);
    }
    return filtered;
  };

  /* Picking out a spesific episode based on link url string */
  const getArticle = (args: GetArticleFilterArgs) => {
    const type = getArticlePageType();
    return (
      articles.find(
        (item) => item.slug === params.slug && item.type === type
      ) || null
    );
  };

  const getArticleCountByType = () => {
    const type = getArticlePageType();
    return articles.filter((item) => item.type === type).length;
  };

  /* Showing 0-2 indexes in array (manipulating copied array with ...) */
  const getPaginatedArticles = (args: GetPaginatedArticlesFilterArgs) => {
    const type = getArticlePageType();
    return [...articles.filter((item) => item.type === type)].splice(
      args.page * 2,
      2
    );
  };

  return (
    /* Implements episode functions in the context provider */
    <DeliveryContext.Provider
      value={{
        main: getMain,
        articles: getArticles,
        getArticle,
        getFirstArticle,
        getPaginatedArticles,
        getArticlePageType,
        getArticleCountByType
      }}
    >
      {children}
    </DeliveryContext.Provider>
  );
};
